<template>
   <v-container class="my-4" :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'}; background-color: #fff;`">
    <v-row justify="center">
        <v-col cols="12" md="12">
        <h1>LIÊN HỆ</h1>
        </v-col>
      <v-col cols="12" md="4">
        <h3 style="background-color: #f9f9f9; padding: 5px;">ĐỊA CHỈ: LẦU 8 - TÒA NHÀ ABACUS TOWER, 58 NGUYỄN ĐÌNH CHIỂU, PHƯỜNG ĐA KAO, QUẬN 1, TP.HCM</h3>
        <h3 style="background-color: #f9f9f9; padding: 5px;" class="my-4">SỐ ĐIỆN THOẠI: 1900 636870</h3>
        <h3 style="background-color: #f9f9f9; padding: 5px;">EMAIL: INFO@CONASI365.VN</h3>
      </v-col>
      <v-col cols="12" md="4">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31354.313909453344!2d106.699901!3d10.789145!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4ac6351674cecdf7!2zQ8OUTkcgVFkgQ-G7lCBQSOG6pk4gUVXhuqJOIEzDnSBC4bqkVCDEkOG7mE5HIFPhuqJOIENPTkFTSQ!5e0!3m2!1sen!2sus!4v1630932774964!5m2!1sen!2sus" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </v-col>
      <v-col cols="12" md="4">
        <v-form ref="form">
            <v-container>
                <v-row>
                <v-col cols="12" md="12" style="margin: 0 !important; padding: 0 !important;">
                    <v-text-field
                    :label="$t('Họ tên')"
                    outlined
                    required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" style="margin: 0 !important; padding: 0 !important;">
                    <v-text-field
                    :label="$t('Email')"
                    outlined
                    required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" style="margin: 0 !important; padding: 0 !important;">
                    <v-text-field
                    :label="$t('Số điện thoại')"
                    outlined
                    required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" style="margin: 0 !important; padding: 0 !important;">
                    <v-textarea
                    :label="$t('Ghi chú')"
                    outlined
                    required
                    row="4"
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="12" class="text-right" style="margin: 0 !important; padding: 0 !important;">
                    <v-btn color="primary">Gửi</v-btn>
                </v-col>
                </v-row>
            </v-container>
            </v-form>
      </v-col>
    </v-row>
   </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>